import { useRouteLoaderData } from "react-router"
import { type loader as rootLoader } from '#app/root.tsx'

export function useIsMobile() {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	
	if (!data) {
		throw new Error(
			'No data found in root loader.',
		)
	}
	
	return data.isMobile
}